h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: capitalize;
    text-align: left;
}

p {
    text-align: left;
}

.text-left {
    text-align: left;
}

.hide-astricks label.ant-form-item-required::before {
    display: none !important;
}