.ant-notification-notice-success {
    .ant-notification-notice-message {
        color: #004D40 !important;
        font-weight: 600;
    }
}

.ant-notification-notice {
    display: flex;
    // justify-content: center;
    align-items: center;
    align-content: center;
}

// Tabs

// .account-tabs .ant-tabs-tab {

// }

.ant-form-item-feedback-icon.ant-form-item-feedback-icon-success,
.ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
    display: flex;
}

.ant-form-item-explain-error {
    text-align: left;
}

.ant-popover-buttons {
    display: flex;
}

// 

.ant-modal-footer {
    display: flex;
    justify-content: end;
}

// 


.account-tabs .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 60px !important;
}

// 

.checkboxes-vertical .ant-checkbox-wrapper {
    display: flex;
    margin-bottom: 10px;
}


img {
    display: unset !important;
}

.custom-search {
    border-radius: 100px !important;
    padding: 5px 20px !important;
    font-size: 18px !important;


    .ant-input,
    .ant-input::placeholder {
        font-size: 16px !important;
    }

    .ant-input-prefix {
        margin-right: 15px;
    }
}

//  ----- > ........ -----> // 

.ant-collapse-expand-icon {
    display: flex !important;
    align-items: center !important;
}

.ant-collapse-header {
    display: flex !important;
    align-items: center !important;
}