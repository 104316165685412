@import "./global";
@import "./header";
@import "./antd";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');


html,
body {
    height: 100%;
}

body {
    font-family: 'inter';
}