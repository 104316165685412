.header-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;

    align-items: center;
    justify-content: space-between;

    .icon {
        font-size: 1.5rem;
        margin-left: 20px;
    }
}

.header-nav {
    padding: 24px;
    border-bottom: 1px solid transparentize(#28b3fe, .9);
    box-shadow: 0px 3px 10px -2px transparentize(#28b3fe, .9);
}


.header-container h1 {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: -.5px;
    text-transform: capitalize;
}


.hamburger {
    display: block;
    position: absolute;
    left: 90vw;
    z-index: 1;
    user-select: none;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;

    span {
        display: block;
        width: 25px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;

        background-color: black;
        border-radius: 6px;
        z-index: 1;

        transform-origin: 0 0;
        transition: 0.4s;
    }




}

.hamburger.is-active span:nth-child(2) {
    transform: translateY(-7px) rotate(45deg);

}

.hamburger.is-active span:nth-child(1) {
    transform: translateY(-2px) rotate(45deg);
}

.hamburger.is-active span:nth-child(3) {
    transform: translate(-2px, 2px) rotate(-45deg);
}

.hamburger.is-active span:nth-child(2) {
    opacity: 0;
}





.menu {
    display: none;
    flex: 1 1 0%;
    justify-content: flex-end;

    .item {
        color: rgb(67, 67, 67);
        margin: 0 16px;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
    }
}


.menu a:hover {
    color: rgb(27, 173, 251);

}


@media(min-width: 768px) {
    .hamburger {
        display: none;
    }

    .menu {
        display: flex;
    }

}

@media(max-width: 768px) {
    .container {
        justify-content: center;

        .icon {
            position: absolute;
            left: 1vw;
        }
    }

    .menu.shrinked {
        display: flex;
        flex-direction: column;
        position: absolute;
        text-align: left;
        font-size: large;
        top: 80px;
        background-color: white;

        opacity: 100;


        width: 100%;

        .item {
            display: block;
            margin: 10px;
            padding-left: 10px;
        }
    }


}